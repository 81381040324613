import axios, {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from 'axios';

class Api {

    public static get<T = unknown>(path: string, authToken?: string): Promise<AxiosResponse<T>> {
        return axios.get(getApiPath(path), getConfig(authToken));
    }

    public static post<T = unknown>(
        path: string,
        body: Record<string, unknown>,
        authToken?: string,
    ): Promise<AxiosResponse<T>> {
        return axios.post(getApiPath(path), body, getConfig(authToken));
    }

}
function getConfig(authToken: string = ''): AxiosRequestConfig {
    const headers: AxiosRequestHeaders = {};
    if (authToken.length) {
        headers.Authorization = authToken;
    }

    return {
        headers,
    };
}
function getApiPath(path: string): string {
    return `${process.env.GATSBY_API_URL}${path.startsWith('/') ? '' : '/'}${path}`;
}

export {Api};
