import * as React from 'react';
import {AuthToken} from '../auth-token';
import {AxiosResponse} from 'axios';
import {Api} from '../api';
import {TextField} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import {styled as muiStyled} from '@mui/material/styles';
import '../styles/login.css';

interface ILoginState {
    name: string;
    password: string;
    isLoading: boolean;
    error: ErrorType | null;
}
enum ErrorType {
    WrongCredentials = 'WrongCredentials',
}

class Login extends React.Component<{}, ILoginState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            name: '',
            password: '',
            isLoading: false,
            error: null,
        };
    }

    public async onFormSubmit(event: React.SyntheticEvent): Promise<void> {
        event.preventDefault();
        if (!this.state.name || !this.state.password) {
            return;
        }
        this.setState({isLoading: true});
        try {
            const result: AxiosResponse<IAuthResult> = await Api.post(
                '/auth',
                {
                    username: this.state.name,
                    password: this.state.password,
                },
            );
            const token: string = result.data.data.token;

            AuthToken.set(token);
            const params = new URLSearchParams(location.search);
            const redirectTo: string | null = params.get('redirectTo') || null;
            if (redirectTo) {
                location.href = decodeURIComponent(redirectTo);
            } else {
                location.href = '/';
            }

        } catch (error) {
            this.setState({error: ErrorType.WrongCredentials});
        }
        this.setState({isLoading: false});
    }

    public render(): React.ReactNode {
        return (
            <ContentWrapper>
                <section className="login">
                    <div className="header"/>
                    <form className="login-content">
                        <div className="logo"/>
                        <div className="form">
                            <InputField
                                error={this.state.error !== null}
                                id="name"
                                label="Name"
                                variant="filled"
                                margin="normal"
                                fullWidth
                                value={this.state.name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({name: event.target.value, error: null});
                                }}
                            />
                            <InputField
                                error={this.state.error !== null}
                                helperText={getFormError(this.state.error)}
                                id="password"
                                label="Password"
                                type="password"
                                variant="filled"
                                margin="normal"
                                fullWidth
                                value={this.state.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({password: event.target.value, error: null});
                                }}
                            />
                            <LoginButton
                                type="submit"
                                loading={this.state.isLoading}
                                onClick={this.onFormSubmit.bind(this)}
                            >Login</LoginButton>
                        </div>
                    </form>
                </section>
            </ContentWrapper>
        );
    }
}

function getFormError(error: ErrorType | null): string {
    switch (error) {
        case null:
        default:
            return '';
        case ErrorType.WrongCredentials:
            return 'Invalid login data';
    }
}

const InputField = muiStyled(TextField)(() => ({
    'color': 'rgba(0, 0, 0, 0.4)',
    '& label.Mui-focused': {
        color: 'hsla(0,0%,100%,.8)',
    },
    '& .MuiFilledInput-root': {
        backgroundColor: 'hsla(0,0%,100%,.4)',
    },
}));

const LoginButton = muiStyled(LoadingButton)(() => ({
    'height': '42px',
    'width': '100%',
    'marginTop': '20px !important',
    'color': 'var(--login-button-color)',
    'backgroundColor': 'var(--login-button-background-color) !important',
    '&:hover': {
        backgroundColor: 'var(--login-button-background-color)',
    },
}));

interface IAuthResult {
    data: {
        token: string;
    };
}

const ContentWrapper = styled.div`
    height: 100%;
`;

export {Login as default, Login};
